@import '../../../../static/scss/variables';

.widget-editor-section {
  .widget-editor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: $white;
  }
  .widget-editor-body-section {
    background-color: $lightgray-2;

    .widget-editor-tabs-container {
      position: absolute;
      height: auto;
      // margin:20px;
      .nav-tabs {
        flex-direction: row !important;
        // padding-top: 15px;
        flex-wrap: unset !important;
        overflow: auto;
        overflow-y: hidden;
        white-space: nowrap;
        // border: none;
      }
      .tab-ui {
        display: inline-block;
        text-align: center;
        padding: 14px;
        text-decoration: none;
        background-color: $white !important;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        box-shadow: 0 0 3px 0 $lightgray;
        &:not(:first-child) {
          margin-left: 3px;
        }
        &.active {
          background-color: $lightgray-2 !important;
          font-weight: 700;
          border-top: 3px solid var(--primary-2);
        }
      }
      .collapsible-content {
        background-color: var(--lightgray-2);
        height: calc(100vh - 170px);

        .collapsible-comp-container {
          background: var(--white);
        }
      }
    }
  }

  .integrate-code-container {
    .msg-textarea-field ::-webkit-scrollbar {
      width: 6px;
    }

    .msg-textarea-field ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .msg-textarea-field ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $darkgray-9;
    }

    .wordpress-link {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.widget-editor-container {
  #sidebar-right {
    // position: fixed !important;
  }
}
