@import '../../../../static/scss/variables';

.feature-modal-container {
  .slider-container {
    padding: 1rem 1rem 2rem 1rem; // height: 400px;
    img,
    video {
      height: 350px;
      image-rendering: -moz-crisp-edges; // Firefox /
      image-rendering: -o-crisp-edges; // Opera /
      image-rendering: -webkit-optimize-contrast; // Webkit (non-standard naming) /
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; // IE (non-standard property) /
    }
  }
  .release-version {
    background: var(--primary-4);
    font-weight: 700;
    padding: 0px 20px;
    border-radius: 8px;
    width: fit-content;
    color: var(--primary);
  }
  .disclaimer {
    color: var(--warning-2);
    font-weight: 700;
    font-size: 14px;
    width: fit-content;
  }

  .button-padding {
    padding: 0.5rem 1rem;
  }
  .main-modal-body {
    position: relative;
    .done-btn {
      position: absolute;
      right: 25px;
      bottom: 25px;
    }
    .back-btn {
      position: absolute;
      left: 16px;
      bottom: 27px;
      color: var(--darkgray-2);

      &:hover {
        color: var(--darkgray-3) !important;
      }
    }
  }

  > div {
    .modal-content {
      border-radius: 20px;
    }
  }
  .whats-new-section {
    position: relative;
    .whats-new-container {
      .main-container {
        z-index: 1;
        padding-top: 25px;
      }

      border-radius: 12px 12px 0px 0px;
      padding: 1rem 1.5rem;
      height: 430px;
      background: var(--primary);
      color: var(--primary-4);

      .info-container {
        font-weight: 700;
        font-size: 35px;
        padding-left: 10px;
      }
      .bullet-text {
        font-weight: 400;
        font-size: 18px;
      }
    }
    .title-image-container {
      height: 382px;
      width: 470px;
      position: absolute;
      right: 5%;
      top: 5%;
      .bg-image {
        height: inherit;
        width: inherit;
        background: url('/static/images/feature-tour.png');
        background-size: cover;
      }
    }
    .text-container {
      padding: 1rem 2rem;

      .heading {
        font-weight: 700;
        font-size: 22px;
      }
      .info {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .release-version-heading {
    // margin-top: 15px;
    // border-bottom: 2px lightgray solid;
  }
  .release-underline {
    border-bottom: 2px lightgray solid;
  }

  .info-container {
    // margin: 15px 0px 5px 0px;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .custom-badge {
    visibility: hidden;
  }
  // scrollbar styles
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $lightgray-2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $lightgray-4;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb {
    background: $lightgray-4;
  }
}
