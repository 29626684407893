.logo-apex {
  img {
    margin-top: 10px;
    width: 240px;
  }
}

.logo-onroll {
  img {
    margin-top: 10px;
    width: 100px;
  }
}
