@import '../../static/scss/variables';

.profile-nav {
  position: relative;

  .avatar-styles {
    width: 35px;
    height: 35px;
    border-radius: 25px;
  }
}

.disabled-icon {
  pointer-events: none;
  background: $lightgray;
  z-index: auto;
  svg {
    color: $darkgray-3;
  }
}
