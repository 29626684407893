@import "~react-toastify/scss/variables";

$rt-toast-background: #fff;
$rt-color-default: #fff;
$rt-color-dark: $secondary;
$rt-color-info: $primary;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;
$rt-text-color-default: $darkgray-3;
$rt-text-color-dark: #fff;
$rt-color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);

@import "~react-toastify/scss/toastContainer";
@import "~react-toastify/scss/toast";
@import "~react-toastify/scss/closeButton";
@import "~react-toastify/scss/progressBar";

// entrance and exit animations
@import "~react-toastify/scss/animations/bounce.scss";
@import "~react-toastify/scss/animations/zoom.scss";
@import "~react-toastify/scss/animations/flip.scss";
@import "~react-toastify/scss/animations/slide.scss";

.Toastify__toast {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    word-break: break-word;  //issue:cutting text, if text is large with no space
}