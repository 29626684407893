.onboarding-form-features {
    .toggle-tooltip-wrapper {
        .form-group {
            .custom-switch {
                .custom-control-label {
                    top: 0 !important;
                    right: 10px !important;
                }
            }
        }
    }
}
