@import '../../../../static/scss/variables';

h1 {
  margin: 0;
}
//   .box {
//     position: relative;
//     height: 90vh;
//     width: 300px;
//     overflow: hidden;
//     border: 1px solid red;
//     background: #fff;
//   }
.hide-box {
  top: 100%;
  position: absolute;
  transition: top 0.9s ease-out;
  background: #f4f6f8;
  height: 50%;
  width: 100%;
  .close-button {
    position: absolute;
    top: 13px;
    right: 10%;
    z-index: 8;
    color: $dark;
  }
}

.show-hidden-div {
  top: 50%;
}

.hide-div {
  top: 100% !important;
}

.footer {
  position: absolute;
  bottom: 0;
  z-index: 1000;
}
