@import '../../../../static/scss/variables';
@import '../../../../static/scss/functions';

.userModal {
  border-radius: 0.5rem;

  //   width: 480px;
  .modal-content {
    border-radius: 0.5rem;
    height: 740px;
    .modal-body {
      padding: 2rem;
      .modal-title {
        color: var(--primary);
        font-weight: 600;
        margin-bottom: 24px;
      }
      .form {
        &-label {
          left: 1rem;
          font-size: pxToRem(13);
          color: $darkgray-1;
          font-family: Lato;
          font-weight: 500;
        }
        &-group {
          margin-bottom: 1rem;
          .passEye {
            display: none;
          }
          &.has-value {
            .form-label {
              font-size: pxToRem(10);
              font-family: Lato;
              color: $darkgray-2;
              top: 0.5rem;
            }
            .form-control {
              padding-top: pxToRem(14);
            }
            .passEye {
              display: inline;
              cursor: pointer;
              right: 0.75rem;
              position: absolute;
              top: 0.625rem;
              color: $darkgray-2;
            }
          }
          &.is-invalid {
            .form-control {
              border-color: $danger-1;
            }
            .form-label {
              color: $danger-1;
            }
          }
          .toggleBtn {
            display: inline;
            cursor: pointer;
            right: 0.75rem;
            position: absolute;
            top: 0.625rem;
            color: $darkgray-2;
          }
          .form-control {
            padding: 0 1rem;
            height: 3rem;
            color: $darkgray-1;
            font-family: Lato;
            font-size: pxToRem(13);
            &.lined {
              &:focus + .form-label {
                font-size: 10px;
                top: 0.5rem;
              }
              &.form-control {
                &:focus {
                  padding-top: pxToRem(14);
                }
              }
            }
          }
          .form-control {
            &.lined {
              border-width: 1px;
              border-radius: 0.25rem;
            }
          }
        }
        button {
          margin-top: 1rem;
          span {
            color: $primary-4;
          }
        }
      }
    }
  }
}

.user-modal-header {
  color: $darkgray-1;
  font-weight: bold;
  background: $lightgray-2;
  border: none;
  font-family: Lato;
  // font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}

.user-modal-header-container {
  height: 62px;
}

.user-modal-footer {
  background: $lightgray-2;
  border: none;
}
.form-class {
  padding: 20px;
}

.user-modal-body {
  padding: 0px !important;
  .navbar-expand {
    background-color: $lightgray-2;
    margin-top: -2px;
  }
  .tab-styles {
    font-family: Lato;
    // font-size: 15px;
    font-size: 0.938rem;
    line-height: 22px;
    text-align: center;
    font-weight: Bold;
    padding-left: 18px;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-right: 23px;
  }
}

.white-background {
  background: #ffffff;
}

.profile-image {
  border: 1px solid $lightgray;
  border-radius: 5px;
  height: 120px;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f9fafb;

  .avatar-img {
    height: 88px;
    width: 88px;
    border-radius: 4px;
  }
}
.avatar-img {
  height: 88px;
  width: 88px;
  border-radius: 4px;
}
